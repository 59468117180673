import React from 'react';
import { rendTable, rendModal, api, loading } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Log Kasir";
    document.title = "Log Kasir";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "ID",
                by: "DESC"
            };
        }

        async rendInfo(e) {
            const sql = await api("hardware_detail", { iddata: e.target.dataset.id });
            let dom = <iframe width="100%" height="450px" frameborder="0" style={{ border: "0" }} title="data"
                src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyB_795sI-eYMaKoMy4Dvkrxtxz3qYXWb6A&q=" + sql.Latitude + "," + sql.Longitude}
                allowfullscreen>
            </iframe>;
            rendModal({
                title: "Lokasi Device",
                body: dom,
                cls: "modal-xl",
                id: "modalInfo"
            });
        }

        main() {
            rendTable({
                url: "log_kasir_browse",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: {cls: "table table-striped"},
                thead: {cls: "color-bg-biru"},
                tbody: {
                    opsi: [{ type: "other", event: (e) => this.rendInfo(e), icon: "room" }],
                    id: "tbodyData"
                }
            });
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

export default App;