import React from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, loading, api, submitForm } from '../modul';

function App() {
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameLocation",
                by: "ASC"
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalProcess(ini, ssts) {
            let dom = (
                <>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="status" value={ssts} />
                    <h5>Apakah Anda yakin akan {ssts} data ini</h5>
                </>
            );
            rendModal({
                id: "modalProses",
                body: dom,
                title: ssts + " Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">save</i>Proses</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "hardware_update", reload: true, modal: "btnCloseModalmodalProses", form: "#iniForm" });
            });
        }

        async modalInfo(e) {
            const sql = await api("hardware_detail", { iddata: e.target.dataset.id });
            const dom = <iframe width="100%" height="450px" frameborder="0" style={{ border: "0" }}
                src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyB_795sI-eYMaKoMy4Dvkrxtxz3qYXWb6A&q=" + sql.Latitude + "," + sql.Longitude}
                allowfullscreen >
            </iframe >;
            rendModal({
                title: "Info Hardware",
                body: dom,
                id: "modalInfo",
                cls: "modal-xl"
            });
        }

        main() {
            rendTable({
                url: "hardware_browse",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "other", event: (e) => this.modalInfo(e), icon: "room" },
                            { type: "other", event: (e) => this.modalProcess(e, 'Approve'), icon: "mobile_friendly" },
                            { type: "other", event: (e) => this.modalProcess(e, 'Reject'), icon: "close" }
                        ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;