import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, loading, api, submitForm, getCookie } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Cost Template";
    document.title = "Cost Template";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "ID",
                by: "ASC"
            };
        }

        main() {
            rendTable({
                url: "costtemplate_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi: [{ type: "edit", event: (e) => rendForm(e) }, { type: "delete", event: (e) => this.modalDelete(e) }], id: "tbodyData"
                }
            });
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "costtemplate_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Template</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                code: "",
                name: "",
                isadmin: "1",
                issales: "1",
                iscommision: "0",
                coaCode: "",
                coaName: "",
                coaID: ""
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("costtemplate_detail", { iddata: this.state.id });
                this.setState({
                    code: sql.data[0].CodeCost,
                    name: sql.data[0].NameCost,
                    isadmin: sql.data[0].isAdmin,
                    issales: sql.data[0].isSales,
                    iscommision: sql.data[0].isCommision,
                    coaCode: sql.data[0].CoaCode,
                    coaName: sql.data[0].CoaName,
                    coaID: sql.data[0].CoaID
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleCheckbox(e, ssts) {
            this.setState({ [ssts]: e.target.checked === true ? 1 : 0 });
        }

        modalCoa() {
            let dom = (
                <Fragment>
                    <input type="search" class="form-control" id="edtCari" onChange={(e) => this.cariData(e)} placeholder="Silahkan cari nama coa disini" />
                    <p></p>
                    <table class="table table-striped">
                        <thead><tr><th>Code</th><th>Nama</th></tr></thead>
                        <tbody id="tbodyCoa"></tbody>
                    </table>
                </Fragment>
            );

            rendModal({ title: "Cari Coa", body: dom, id: "modalCariCoa" });
        }

        async cariData(e) {
            let sql = await api("coa_browse", { search: e.target.value });
            let tr = sql.data.map(td => <tr style={{ cursor: "pointer" }} onClick={() => this.pilihCoa(td)} id={td.ID}><td>{td.CoaCode}</td><td>{td.CoaName}</td></tr>);
            ReactDOM.render(tr, document.getElementById('tbodyCoa'))
        }

        pilihCoa(ini) {
            this.setState({
                coaCode: ini.CoaCode,
                coaID: ini.ID,
                coaName: ini.CoaName
            });
            document.getElementById('btnCloseModalmodalCariCoa').click();
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="isadmin" id="edtAdmin" value={this.state.isadmin} />
                    <input type="hidden" name="issales" id="edtSales" value={this.state.issales} />
                    <input type="hidden" name="iscommision" id="edtCommision" value={this.state.iscommision} />
                    <input type="hidden" name="coaID" id="edtCoaID" value={this.state.CoaID} />
                    <input type="hidden" name="coaCode" id="edtCoaCode" value={this.state.CoaCode} />
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-group">
                                <label>Kode Biaya</label>
                                <input type="text" name="code" class="form-control" id="edtCode" value={this.state.code} onChange={(e) => this.handleChange(e, "code")} required />
                                <div class="invalid-feedback">Silahkan Isi Code biaya</div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2">
                            <div class="form-group">
                                <label>Nama Biaya</label>
                                <input type="text" name="name" class="form-control" id="edtNama" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                                <div class="invalid-feedback">Silahkan Isi Nama biaya</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Coa Refference</label>
                        <div class="input-group">
                            <input type="text" className="form-control" name="coaName" id="edtCoaName" value={this.state.coaName} onClick={(e) => this.modalCoa(e)} readonly />
                            <button className="btn btn-default" type="button" onClick={(e) => this.modalCoa(e)}><i className="material-icons">search</i></button>
                        </div>
                    </div>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="chkAdmin" onChange={(e) => this.handleCheckbox(e, "isadmin")} checked={this.state.isadmin} />
                        <label class="form-check-label" for="chkAdmin">Tampilkan di admin</label>
                    </div>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="chkSales" onChange={(e) => this.handleCheckbox(e, "issales")} checked={this.state.issales} />
                        <label class="form-check-label" for="chkSales">Tampilkan di sales</label>
                    </div>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="chkKomisi" onChange={(e) => this.handleCheckbox(e, "iscommision")} checked={this.state.iscommision} />
                        <label class="form-check-label" for="chkKomisi">Jenis komisi</label>
                    </div>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "costtemplate_edit" : "costtemplate_insert",
        title = id ? "Edit Template Biaya" : "Tambah Template Biaya";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;