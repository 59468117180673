import React, { Fragment } from 'react';
import { rendTable, randomRgb, api, tanggalIndo, saiki, exportData, loading, cekStatus } from "../modul";
import ReactDOM from 'react-dom';
import { Line, Bar } from 'react-chartjs-2';

function App() {
    document.getElementById("lblHeader").innerHTML = "Laporan Jenis Pembayaran";
    document.title = "Laporan Jenis Pembayaran";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "kasir",
                by: "DESC",
                mode: "hari",
                d1: saiki(),
                d2: saiki(),
                location: 0,
                bln: saiki().substring(0, 7),
                chart: {
                    labels: [],
                    datasets: []
                }
            };
        }

        handleMode(mode) {
            this.setState({ mode: mode });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        componentDidMount() {
            if (sessionStorage.getItem("d1")) this.setState({ d1: sessionStorage.getItem("d1") });
            if (sessionStorage.getItem("d2")) this.setState({ d2: sessionStorage.getItem("d2") });
            if (sessionStorage.getItem("bln")) this.setState({ bln: sessionStorage.getItem("bln") });
            setTimeout(() => {
                this.main();
                cekStatus();
                document.getElementById('edtD1').value = tanggalIndo(this.state.d1);
                document.getElementById('edtD2').value = tanggalIndo(this.state.d2);
                loading();
            }, 200);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            sessionStorage.setItem([ssts], e.target.value);
        }

        async main() {
            let WARNA = [],
                TTL = [],
                dataset = [],
                arrTgl = [],
                arrAkun = [],
                arrJml = [],
                thead = [];
            document.getElementById('edtD1').style.display = "block";
            document.getElementById('edtD2').style.display = "block";
            document.getElementById('edtMonth').style.display = "none";
            document.getElementById('divChartBar').style.display = "none";
            document.getElementById('divChartLine').style.display = "block";

            let sqlNew = await api("data_payment_web2", { month: this.state.bln, mode: this.state.mode, d1: this.state.d1, d2: this.state.d2 });

            for (let pay of sqlNew.data) {
                if (!arrTgl.includes(pay.Tanggal.substring(8, 10))) arrTgl.push((pay.Tanggal.substring(8, 10)));
                if (!arrAkun.includes(pay.NamePayment)) if (pay.NamePayment != null) arrAkun.push(pay.NamePayment);
                if (!arrJml.includes(pay.Amount)) arrJml.push(pay.Amount);
            }
            for (let pay of arrAkun) {
                let data = [];
                let tot = 0;
                for (let tgl of arrTgl) {
                    for (let akun of sqlNew.data) {
                        if (tgl == akun.Tanggal.substring(8, 10) && pay == akun.NamePayment) {
                            data.push(akun.Amount);
                            tot += parseInt(akun.Amount);
                        }
                    }
                }
                dataset.push({
                    "label": pay,
                    "data": data,
                    "fill": false,
                    "borderColor": randomRgb(),
                    "backgroundColor": randomRgb(),
                    "lineTension": 0.1
                });
                WARNA.push(randomRgb());
                TTL.push(tot);
            }

            if (this.state.mode == "hari") {
                document.getElementById('edtD1').style.display = "block";
                document.getElementById('edtD2').style.display = "block";
                document.getElementById('edtMonth').style.display = "none";
                document.getElementById('divChartBar').style.display = "block";
                document.getElementById('divChartLine').style.display = "none";
                this.setState({
                    chart: {
                        labels: arrAkun,
                        datasets: [{
                            label: "Data Outlet Harian",
                            data: TTL,
                            backgroundColor: WARNA,
                            borderColor: WARNA,
                            borderWidth: 1
                        }]
                    }
                });

            } else {
                document.getElementById('edtD1').style.display = "none";
                document.getElementById('edtD2').style.display = "none";
                document.getElementById('edtMonth').style.display = "block";
                this.setState({
                    chart: {
                        labels: arrTgl,
                        datasets: dataset
                    }
                });
            }

            let pivot = await api("pivot_payment", {
                idlocation: this.state.location,
                d1: this.state.d1,
                d2: this.state.d2,
                month: this.state.bln,
                sort: this.state.sort,
                by: this.state.by,
                mode: this.state.mode
            });

            for (let th of pivot.field) thead.push({ cap: th.Field, sort: th.Field });

            let tempData = { field: { thead: thead }, data: pivot.data, };
            rendTable({
                dataset: tempData,
                div: "divGrid",
                table: { cls: "table table-striped" },
                thead: { cls: "thead-dark" },
                tbody: { id: "tbodyData" }
            });
        }

        async handleDownload(e) {
            e.target.innerHTML = `<span class="material-icons">hourglass_bottom</span>`;
            e.target.disabled = true;
            let pivot = await api("pivot_payment", {
                idlocation: this.state.location,
                d1: this.state.d1,
                d2: this.state.d2,
                month: this.state.bln,
                sort: this.state.sort,
                by: this.state.by,
                mode: this.state.mode
            });
            let thead = [];
            for (let th of pivot.field) thead.push({ cap: th.Field, sort: th.Field });

            let tempData = { field: { thead: thead }, data: pivot.data, };
            exportData(tempData, `Data Laporan Jenis Pembayaran ${tanggalIndo(this.state.d1)} - ${tanggalIndo(this.state.d2)}`);
            setTimeout(() => {
                e.target.innerHTML = `<span class="material-icons">file_download</span>`;
                e.target.disabled = false;
            }, 2000);
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                    <div className="input-group gap-2">
                                        <h5 id="hPeriod">Periode : </h5>
                                        <input type="text" id="edtD1" style={{maxWidth:"120px"}} className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d1")} onMouseOver={(e) => this.setType(e, 'focus', 'd1')} onBlur={(e) => this.setType(e, "blur", 'd1')} />
                                        <input type="text" id="edtD2" style={{maxWidth:"120px"}} className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d2")} onMouseOver={(e) => this.setType(e, 'focus', 'd2')} onBlur={(e) => this.setType(e, "blur", 'd2')} />
                                        <input type="month" name="edtMonth" style={{maxWidth:"150px"}} id="edtMonth" className="form-control form-control-sm" value={this.state.bln} onChange={(e) => this.handleChange(e, "bln")} />
                                        <button className="btn btn-sm btn-default" id="btnPreview" onClick={() => this.main()}><i className="material-icons">search</i></button>
                                        <button className="btn btn-sm btn-default" id="btnExport" onClick={(e) => this.handleDownload(e)}><i className="material-icons">file_download</i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="d-flex justify-content-end align-items-center">
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-hari-tab" data-bs-toggle="pill" role="tab" aria-controls="pills-home" aria-selected="true" onClick={(e) => this.handleMode('hari')}>HARI</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-bulan-tab" data-bs-toggle="pill" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={(e) => this.handleMode('bulan')}>BULAN</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="divView">
                            <div className="card shadow rounded" id="divParentHari" style={{ minHeight: "220px;" }}>
                                <div id="divChartBar" style={{ display: "none" }}>
                                    <Bar
                                        height={"50px"}
                                        data={this.state.chart}
                                    />
                                </div>
                                <div id="divChartLine">
                                    <Line
                                        height={"50px"}
                                        data={this.state.chart}
                                    />
                                </div>
                            </div>
                            <p></p>
                            <div className="card shadow rounded" style={{ minHeight: "220px;" }}>
                                <div className="card-body">
                                    <div className="table-responsive" id="divGrid">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

export default App;