import React, { Fragment } from 'react';
import { rendTable, rendModal, api, submitForm, loading, getCookie, numberFormat } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Master Barberman";
    document.title = "Master Barberman";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameOperator",
                by: "ASC",
                DataProduct: [],
                DataProductBarberman: [],
                IDOperator: 0,
                NameOperator: ""
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </ Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "operator_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        setProductBarberman(data) {
            let DataProductBarberman = this.state.DataProductBarberman;
            DataProductBarberman.push({
                IDProduct: data.ID,
                NameProduct: data.NameProduct,
                Price: data.Price
            });
            this.setState({ DataProductBarberman: DataProductBarberman });
        }

        async modalProduct(ini) {
            let sql = await api("detail_barberman_product", { IDOperator: ini.target.dataset.id });
            if (sql.status == "true") {
                this.setState({ DataProduct: sql.product, DataProductBarberman: sql.data, IDOperator: ini.target.dataset.id });
                let modal = new window.bootstrap.Modal(document.getElementById("modalProduk"), {});
                modal.show("#modalProduk");
            }
        }

        main() {
            rendTable({
                url: "operator_browse_web",
                param: {
                    search: this.state.search,
                    searchcount: this.state.searchcount,
                    index: this.state.index,
                    idlocation: localStorage.getItem("location"),
                    sort: this.state.sort,
                    by: this.state.by
                },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "edit", event: (e) => rendForm(e) },
                            { type: "delete", event: (e) => this.modalDelete(e) },
                            { type: "other", icon: "settings", event: (e) => this.modalProduct(e) }
                        ],
                    id: "tbodyData"
                }
            });
        }

        handleDeleteProduct(i) {
            let data = this.state.DataProductBarberman;
            let temp = [];
            for (let dd in data) if (dd != i) temp.push(data[dd]);
            this.setState({ DataProductBarberman: temp });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Barberman</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                        </div>
                    </div>
                    <nav id="paggination"></nav>

                    <div className="modal fade" id="modalProduk" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <form onSubmit={(e) => submitForm(e, { act: "insert_barberman_product", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniFormBarber" })} id='iniFormBarber' className='needs-validation' noValidate>
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Produk Barberman</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <input type="hidden" name="Product" value={JSON.stringify(this.state.DataProductBarberman)} />
                                        <input type="hidden" name="UpdateBy" value={getCookie("ID")} />
                                        <input type="hidden" name="IDOperator" value={this.state.IDOperator} />
                                        <input type="hidden" name="NameOperator" value="" />
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="table-responsive">
                                                    <table className="table table-stripped">
                                                        <thead>
                                                            <tr>
                                                                <th>Kode</th>
                                                                <th>Nama</th>
                                                                <th>Harga</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.DataProduct.map((tr, i) => {
                                                                    return (<tr key={i} onClick={() => this.setProductBarberman(tr)}>
                                                                        <td>{tr.CodeProduct}</td>
                                                                        <td>{tr.NameProduct}</td>
                                                                        <td>{numberFormat(tr.Price)}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <table className="table table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>Kode</th>
                                                            <th>Nama</th>
                                                            <th>Harga</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.DataProductBarberman.map((tr, i) => {
                                                                return (<tr key={i}>
                                                                    <td>{tr.CodeProduct}</td>
                                                                    <td>{tr.NameProduct}</td>
                                                                    <td>{numberFormat(tr.Price)}</td>
                                                                    <td>
                                                                        <button type='button' className='btn btn-opsi' onClick={(e) => this.handleDeleteProduct(i)}>
                                                                            <span className='material-icons'>delete</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnCloseModalmodalDelete" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-primary">Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                code: "",
                name: "",
                desc: "",
                telp: "",
                address: "",
                idlocation: "",
                namelocation: "",
                username: "",
                pwd: "0000",
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("operator_detail", { iddata: this.state.id });
                this.setState({
                    code: sql.data[0].CodeOperator,
                    name: sql.data[0].NameOperator,
                    desc: sql.data[0].Description,
                    telp: sql.data[0].Telp,
                    address: sql.data[0].Address,
                    idlocation: sql.data[0].IDLocation,
                    namelocation: sql.data[0].NameLocation,
                    username: sql.data[0].Username,
                    pwd: "0000",
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        async handleLokasi(e) {
            this.setState({ namelocation: e.target.value });
            let sql = await api("location_browse", { search: e.target.value, searchcount: 100, index: 1 });
            let dom = sql.data.map(opt =>
                <li><a className="dropdown-item" href="#" onClick={() => this.pilihLokasi(opt.NameLocation, opt.ID)} > {opt.NameLocation}</a></li>
            );
            ReactDOM.render(dom, document.getElementById('ulLokasi'));
        }

        pilihLokasi(nama, id) {
            this.setState({ idlocation: id, namelocation: nama });
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Kode Barberman</label>
                                <input type="text" name="code" className="form-control" id="edtCode" value={this.state.code} onChange={(e) => this.handleChange(e, "code")} required />
                                <div className="invalid-feedback">Silahkan pilih lokasi</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Nama Barberman</label>
                                <input type="text" name="name" className="form-control" id="edtName" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                                <div className="invalid-feedback">Silahkan pilih lokasi</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" name="username" className="form-control" id="edtUsername" value={this.state.username} onChange={(e) => this.handleChange(e, "username")} required />
                                <div className="invalid-feedback">Silahkan isi Username</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" name="pwd" className="form-control" id="edtPwd" value={this.state.pwd} onChange={(e) => this.handleChange(e, "pwd")} required />
                                <div className="invalid-feedback">Silahkan isi Pwd</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Lokasi</label>
                                <input type="hidden" name="idlocation" id="edtLokasiID" value={this.state.idlocation} />
                                <div className="dropdown">
                                    <input type="text" name="lokasi" className="form-control" id="edtLokasi" value={this.state.namelocation} onChange={(e) => this.handleLokasi(e)} data-bs-toggle="dropdown" aria-expanded="false" autoComplete="off" required />
                                    <div className="invalid-feedback">Silahkan pilih lokasi</div>
                                    <ul className="dropdown-menu w-100" id="ulLokasi" aria-labelledby="edtLokasi"></ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Telp</label>
                                <input type="number" name="telp" className="form-control" id="edtTelp" value={this.state.telp} onChange={(e) => this.handleChange(e, "telp")} required />
                                <div className="invalid-feedback">Silahkan isi telp</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Alamat</label>
                                <textarea name="address" className="form-control" id="edtAlamat" value={this.state.address} onChange={(e) => this.handleChange(e, "address")} required></textarea>
                                <div className="invalid-feedback">Silahkan isi alamat</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Deskripsi</label>
                                <textarea name="desc" className="form-control" id="edtDesc" value={this.state.desc} onChange={(e) => this.handleChange(e, "desc")}></textarea>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "operator_edit" : "operator_insert",
        title = id ? "Edit Barberman" : "Tambah Barberman";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-lg"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;